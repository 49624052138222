// 滚动方向枚举值
const DIRECTION_ENUM = {DOWN: 'down',UP: 'up'};
import {debounce} from '@/utils/debounce.js';
export default{	
	scrollToBottom:function(fn,delay) {
		let threshold = 50,// 距离顶部或底部的阈值
			scrollTop=0,// 距顶部
			clientHeight=0,// 可视区高度
			scrollHeight=0,	// 滚动条总高度		
			beforeScrollTop = 0;// 记录前一个滚动位置
		var delay = delay || 100;
		var th = this,args = arguments;
		window.addEventListener('scroll',debounce(function(){
			// 距顶部
			scrollTop =document.documentElement.scrollTop || document.body.scrollTop;
			// 可视区高度
			clientHeight =document.documentElement.clientHeight || document.body.clientHeight;
			// 滚动条总高度
			scrollHeight =document.documentElement.scrollHeight || document.body.scrollHeight;
			
			// 确定滚动方向
			let direction = DIRECTION_ENUM.DOWN;
			if (beforeScrollTop > scrollTop) {
				direction = DIRECTION_ENUM.UP;
			}	
			// 通过滚动方向判断是触底还是触顶
			if (direction == DIRECTION_ENUM.DOWN) {
			  // 滚动触底
			  if (scrollTop + clientHeight + threshold >= scrollHeight) {					
					console.log('滚动触底');
					fn.apply(th, args);
			  }
			}
			
			beforeScrollTop = scrollTop;
		},delay)
		)		
	},
	scrollToTop:function(fn,delay) {
		
		let threshold = 50,// 距离顶部或底部的阈值
			scrollTop=0,// 距顶部
			clientHeight=0,// 可视区高度
			scrollHeight=0,	// 滚动条总高度		
			beforeScrollTop = 0;// 记录前一个滚动位置
			
		var delay = delay || 100;
		var th = this,args = arguments;
		window.addEventListener('scroll',debounce(function(){
			// 距顶部
			scrollTop =document.documentElement.scrollTop || document.body.scrollTop;
			// 可视区高度
			clientHeight =document.documentElement.clientHeight || document.body.clientHeight;
			// 滚动条总高度
			scrollHeight =document.documentElement.scrollHeight || document.body.scrollHeight;
			
			// 确定滚动方向
			let direction = DIRECTION_ENUM.DOWN;
			if (beforeScrollTop > scrollTop) {
				direction = DIRECTION_ENUM.UP;
			}			
			// 通过滚动方向判断是触底还是触顶
			if (direction == DIRECTION_ENUM.UP) {
			  // 滚动触底
			   if (scrollTop <= threshold) {
					console.log('滚动触顶');
					fn.apply(th, args);
			  }
			}
			beforeScrollTop = scrollTop;
		},delay)
		)		
	},
}