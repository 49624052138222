<template>
	<div class="job_detail">
		<div class="home_center">

			<div class="">
				<div class="center_left">
					<div class="left_title">
						<!-- 委托人 -->{{$t('job_detail.WTR')}}
					</div>
					<el-checkbox-group  v-model="listData.entrust_method">
						
						<el-checkbox :label="item.data_code" v-for="item in entrust" :key="item.data_code">{{item.data_value}}</el-checkbox>
					</el-checkbox-group>
					<!-- <el-checkbox-group v-model="listData.entrust_method">
						<el-checkbox :label="item.data_code" v-for="item in entrust" :key="item.data_code">{{item.data_value}}</el-checkbox>
						
					</el-checkbox-group> -->
					
					<div class="left_title">
						<!-- 体裁 -->{{$t('job_detail.TC')}}
					</div>
					<el-checkbox-group v-model="listData.skill">
						
						<!-- <template v-for="item in skillList" >
							<el-checkbox :label="sitem.label" v-for="sitem in item.children" :key="sitem.value" style="display: block;"></el-checkbox>
						</template> -->
						
						<template v-if="showallskill" >
							<el-checkbox :label="sitem.label" v-for="sitem in allskill" :key="sitem.value" style="display: block;"></el-checkbox>
						</template>
						<template v-else >
							<el-checkbox :label="sitem.label" v-for="sitem in tenskill" :key="sitem.value" style="display: block;"></el-checkbox>
						</template>
						<div @click="showallskill=!showallskill" style="font-size: 12px;color: #fff;cursor: pointer;margin-top: 10px;">{{$t('CKGD')}}<i class="el-icon-arrow-up" v-if="showallskill"></i><i class="el-icon-arrow-down" v-else></i></div>
					</el-checkbox-group>
					<!-- <el-checkbox-group v-model="listData.skill">
						<template v-for="item in skillList" >
							<el-checkbox :label="sitem.label" v-for="sitem in item.children" :key="sitem.value"></el-checkbox>
						</template>
					</el-checkbox-group> -->
					<!-- <div class="left_title">
						委托方式
					</div>
					<el-checkbox-group v-model="checkList">
						<el-checkbox label="项目（固定报酬制）"></el-checkbox>
						<el-checkbox label="可商量（时间单价制）"></el-checkbox>
						<el-checkbox label="基于任务"></el-checkbox>
					</el-checkbox-group> -->
					<div class="left_title">
						<!-- 其他筛选 -->{{$t('job_detail.QTSX')}}
					</div>
					<div style="margin-bottom: 30px;">
					    <el-checkbox :true-label="1" :false-label="0" v-model="listData.job_method"><!-- 可远程 -->{{$t('job_detail.KYC')}}</el-checkbox>
					
					    <el-checkbox :true-label="1" :false-label="0" v-model="listData.fast_method"><!-- 急聘 -->{{$t('job_detail.JP')}}</el-checkbox>
					
					    <el-checkbox :true-label="$t('job.FY')" false-label="" v-model="listData.job_nature"><!-- 可兼职 -->{{$t('job_detail.KJZ')}}</el-checkbox>
					</div>
					<!-- <el-checkbox-group v-model="checkList">
						<el-checkbox label="可远程"></el-checkbox>
						<el-checkbox label="急聘"></el-checkbox>
						<el-checkbox label="复数募集"></el-checkbox>
						<el-checkbox label="可兼职"></el-checkbox>
					</el-checkbox-group> -->
					<div class="left_title">
						<!-- 报酬 -->{{$t('job_detail.BC')}}
					</div>
					<div class="left_input_box">
						<el-input v-model="listData.salary_low" placeholder="" class="input"></el-input>
						<div class="input_box">
							<!-- 以上 -->{{$t('MIN')}}〜
						</div>
					</div>
					<div class="left_input_box">
						<el-input v-model="listData.salary_high" placeholder="" class="input"></el-input>
						<div class="input_box">
							{{$t('MAX')}}〜
						</div>
					</div>
					
				</div>

			</div>


			<div class="center_people">
				<div class="center_top">
					<div class="top_title">
						<div class="title_name">
							{{jobInfo.job_name}}
						</div>
						<!-- <img src="../../assets/img/more2.png" class="more" alt=""> -->
					</div>
					<div class="top_id">
						<div class="id_left">
							<div class="id">
								ID：{{jobInfo.job_id}}
							</div>
							<div class="QY" v-if="jobInfo.job_belong_method==1">
								<!-- 三方 -->{{$t('job.SFWT')}}：{{jobInfo.entrust_name}}
							</div>
						</div>

						<div class="pin" @click="gopin">
							
							<!-- 雇主评分 -->{{$t('job_detail.GZPF')}}
							<el-rate :value="jobInfo.score" disabled void-color="#666" disabled-void-color="#666" :colors="colors"></el-rate><span style="color:#FFA034;display: none;">{{jobInfo.score}}<!-- 分 --><!-- {{$t('FEN')}} --></span>
							<div class="morepin">
								<div class="mitem">{{$t('RC')}}:<el-rate :value="jobInfo.schedule_point" disabled void-color="#666" disabled-void-color="#666" :colors="colors"></el-rate></div>
								<div class="mitem">{{$t('BD')}}:<el-rate :value="jobInfo.talk_point" disabled void-color="#666" disabled-void-color="#666" :colors="colors"></el-rate></div>
								<div class="mitem">{{$t('HHQY')}}:<el-rate :value="jobInfo.partner_point" disabled void-color="#666" disabled-void-color="#666" :colors="colors"></el-rate></div>
								
							</div>
						</div>
					</div>
				</div>
				<div class="center_msg">
					<div class="msg_title">
						<div class="title">
							<!-- 基础信息 -->{{$t('job_detail.JCXX')}}
						</div>
						<div class="time">
							<!-- 发布时间 -->{{$t('FBSJ')}}：{{$util.rTime(jobInfo.create_time)}}
						</div>
					</div>
					<div class="activity_box">
						
							<img :src="jobInfo.avatar_url" class="box_img" alt="" style="cursor: pointer;object-fit: cover;" @click="toUserPage(jobInfo.user_id,jobInfo.user_role)">
						
						<div class="box_msg">
							<div class="msg_name">
								<!-- 业务能力 -->{{$t('my_msg.YWNL')}}：
								<div class="name_box">
									<div class="box" v-for="job in jobInfo.skills" :key="job">
										{{job}}
									</div>
								</div>
							</div>
							<div class="flex-start">
								<div><!-- 任务简介 -->{{$t('RWJJ')}}：</div>
								<div class="flex-item" style="word-break: break-all;">
									{{jobInfo.job_desc}}
								</div>
							</div>
							
							<!-- <div class="msg_text">
								必要技能：web开发
							</div> -->
						</div>
						<div class="box_center">
							<div class="center_text">
								<div class="text_text">
									<!-- 工作时间 -->{{$t('job.GZSJ')}}
								</div>
								<div class="text_box" >
									{{jobInfo.oper_time_val}}{{$util.getUnitVal(operTimeMethod,jobInfo.oper_time_method)}}
								</div>
								<!-- <div class="text_box" v-if="jobInfo.oper_time_method==0">
									{{jobInfo.oper_time_val}}{{$t('TIAN')}}
								</div>
								<div class="text_box" v-if="jobInfo.oper_time_method==1">
									{{jobInfo.oper_time_val}}
								</div>
								<div class="text_box" v-if="jobInfo.oper_time_method==3">
									{{$util.getUnitVal(operTimeMethod,jobInfo.oper_time_method)}}
								</div> -->
							</div>
							<!-- <div class="center_text">
								<div class="text_text">
									委托方式
								</div>
								<div class="text_box">
									{{jobInfo.job_belong_method==0?'直接委托':'三方委托'}}
								</div>
							</div> -->
							<div class="center_text">
								<div class="text_text">
									{{$util.getUnitVal(payMethod,jobInfo.pay_method)}}
								</div>
								<div class="text_box">
									{{jobInfo.salary_low}} - {{jobInfo.salary_high}} {{jobInfo.salary_unit}}
								</div>
							</div>
							<div class="center_text">
								<div class="text_text">
									<!-- 需要人数 -->{{$t('other.XYRS')}}
								</div>
								<div class="text_box">
									{{jobInfo.job_num_people}}<!-- 人 -->{{$t('REN')}}
								</div>
							</div>
						</div>


					</div>

					<div class="center_msg_title">
						<!-- 报名情况 -->{{$t('job_detail.BMQK')}}
					</div>
					<div class="one_box">
						<div class="one_box_left">
							<div class="left_menu">
								<div class="menu_num">
									<span>{{jobInfo.apply_count}}</span>
									<!-- 人 -->{{$t('REN')}}
								</div>
								<div class="menu_text">
									<!-- 报名人数 -->{{$t('job_detail.BMRS')}}
								</div>
							</div>
							<div class="xian">

							</div>
							<div class="left_menu">
								<div class="menu_num">
									<span>{{jobInfo.collect_count}}</span>
									<!-- 人 -->{{$t('REN')}}
								</div>
								<div class="menu_text">
									<!-- 收藏人数 -->{{$t('job_detail.SCRS')}}
								</div>
							</div>
							<div class="xian">

							</div>
							<div class="left_menu">
								<div class="menu_num">
									<span>{{jobInfo.pass_count}}</span>
									<!-- 人 -->{{$t('REN')}}
								</div>
								<div class="menu_text">
									<!-- 确定人数 -->{{$t('job_detail.QDRS')}}
								</div>
							</div>
						</div>
						<div class="one_box_right">
							<div class="right_name">
								<!-- 等待贤良 -->{{$t('job_detail.DDXL')}}
							</div>
							<div class="right_msg">
								<!-- <el-statistic ref="statistic" @finish="hilarity" format=" HH:mm:ss" :value="getTimestamp(jobInfo.job_end_time)"
									time-indices>
								</el-statistic> -->
								<countdown v-if="jobInfo.job_end_time" color="#fff" simple :showColon="false" :timestamp="getTimestamp(jobInfo.job_end_time)"></countdown>
								<div class="msg_text">
									<!-- 后截止 -->{{$t('HJZ')}}
								</div>
							</div>
							<div class="right_time">
								<!-- 报名截止 -->{{$t('job_detail.BMJZ')}}：{{jobInfo.job_end_time}}
							</div>
							<div class="btn_box">
								<el-button class="btn1" @click="fav">
									<img src="../../assets/img/biaoq2.png" class="img1" alt="" v-if="jobInfo.is_collect==1">
									<img src="../../assets/img/biaoq.png" class="img1" alt="" v-else>
								</el-button>
								<el-button class="btn1">
									<img src="../../assets/img/fengx.png" class="img1" alt="">
									<div class="sharemenu">
										<div @click.stop="share(job_id,'facebook',jobInfo.job_name)">Facebook</div>
										<div @click.stop="share(job_id,'Linkedin',jobInfo.job_name)">LinkedIn</div>
										<div @click.stop="share(job_id,'twitter',jobInfo.job_name)">Twitter</div>
									</div>
								</el-button>
								<template v-if="user_role==0&&jobInfo.pass_count<jobInfo.job_num_people">
									
								<el-button class="btn2"  disabled @click="applyJob" v-if="jobInfo.is_apply==2">{{$t('YBJ')}}</el-button>
								<el-button class="btn2"  :disabled="jobInfo.is_apply==1" @click="applyJob" v-else>{{jobInfo.is_apply==1?$t('YSQ'):$t('job_detail.SQGZ')}}</el-button>
								<div style="font-size: 12px;margin-left: 10px;cursor: pointer;" v-if="jobInfo.is_apply==1" @click="quxiao">{{$t('CANCEL')}}</div>
								</template>
							</div>
						</div>
					</div>
					<div class="center_msg_title">
						<!-- 招募要求 -->{{$t('job_detail.ZMYQ')}}
					</div>
					<div class="center_two">
						<div class="flex-item">
							<div class="two_menu ">
								<!-- 工作时间 -->{{$t('job.GZSJ')}}：
								{{jobInfo.oper_time_val}}{{$util.getUnitVal(operTimeMethod,jobInfo.oper_time_method)}}
								
							</div>
							<div class="two_menu flex-item">
								<!-- 是否出差 -->{{$t('job.SFCC')}}：{{$util.getUnitVal(tripMethod,jobInfo.trip_method)}}
							</div>
						</div>
						<div class="flex-item">
							<!-- <div class="two_menu">
								{{$t('job.CQSJ')}}：{{$t('EVERY')}}{{$t('JOBTIME')[jobInfo.job_time_method]}}{{jobInfo.job_time_val}}{{$t('GXS')}}
							</div> -->
							<div class="two_menu flex-item">
								<!-- 工作地址 -->{{$t('GZDZ')}}：{{jobInfo.address}}
							</div>
							<div class="two_menu flex-item">
								{{$t('job.SFJP')}}：{{$util.getUnitVal(fastMethod,jobInfo.fast_method)}}
							</div>
							
						</div>
						<div class="two_menu" style="width: auto;">
							<!-- 工作方式 -->{{$t('job.GZFS')}}：{{$util.getUnitVal(jobMethod,jobInfo.job_method)}}
						</div>
						
						
						
					</div>
					<div class="center_msg_title">
						<!-- 工作详情 -->{{$t('job_detail.GZXQ')}}
						<div class="title_right" v-if="jobInfo.related_files.length>0">
							<!-- 相关文件 -->{{$t('job.XGWJ')}}
							<el-button class="btn1" type="primary" @click="downall"><!-- 点击下载 -->{{$t('job_detail.DJXZ')}}</el-button>
						</div>
					</div>
					<div class="center_three" v-html="jobInfo.job_details">
					</div>
					<template v-if="!jobInfo.employeeJob.job_manage_id">
					<div class="center_msg_title">
						<!-- 接包流程 -->{{$t('JBLC')}}
					</div>
					<div class="center_three">
						<!-- <el-steps :space="200" :active="2"  align-center>
						  <el-step title="申请工作"></el-step>
						  <el-step title="雇主选定"></el-step>
						  <el-step title="签订合同"></el-step>
						  <el-step title="开始工作"></el-step>
						  <el-step title="交付工作"></el-step>
						  <el-step title="申请完成"></el-step>
						  <el-step title="交易完成"></el-step>
						</el-steps> -->
						<div class="steps">
							<div class="stepitem">
								<div class="no">1</div>
								<div class="name"><!-- 申请工作 -->{{$t('job_detail.SQGZ')}}</div>
							</div>
							<div class="stepitem">
								<div class="no">2</div>
								<div class="name"><!-- 雇主选定 -->{{$t('job_detail.GZXD')}}</div>
							</div>
							<!-- <div class="stepitem">
								<div class="no">3</div>
								<div class="name">{{$t('job_detail.QDHT')}}</div>
							</div> -->
							<div class="stepitem">
								<div class="no">3</div>
								<div class="name"><!-- 开始工作 -->{{$t('job_detail.KSGZ')}}</div>
							</div>
							<div class="stepitem">
								<div class="no">4</div>
								<div class="name"><!-- 交付工作 -->{{$t('job_detail.JFGZ')}}</div>
							</div>
							<div class="stepitem">
								<div class="no">5</div>
								<div class="name"><!-- 申请完成工作 -->{{$t('SQWCGZ')}}</div>
							</div>
							<div class="stepitem">
								<div class="no">6</div>
								<div class="name"><!-- 完成工作 -->{{$t('work.GZDP')}}</div>
							</div>
						</div>
					</div>
					</template>
					<template v-else>
						<div class="center_msg_title">
							<!-- 任务进度 -->{{$t('job_detail.RWJD')}}
						</div>
						<div class="list_box" v-if="jobInfo.employeeJob.job_progress>2">
							<div class="box_user">
								<div class="user_name">
									<img :src="jobInfo.employeeJob.employee_avatar_url" class="user" alt="">
									{{jobInfo.employeeJob.employee_name}}
								</div>
								<div class="user_right">
									{{$util.rTime(jobInfo.employeeJob.close_date)}}  <!-- 完成结算工作 -->{{$t('WCJSGZ')}}
								</div>
							</div>
						</div>
						<div class="list_box" v-if="jobInfo.employeeJob.job_progress>1">
							<div class="box_user">
								<div class="user_name">
									<img :src="jobInfo.employeeJob.employee_avatar_url" class="user" alt="">
									{{jobInfo.employeeJob.employee_name}}
								</div>
								<div class="user_right">
									{{$util.rTime(jobInfo.employeeJob.submit_date)}}  <!-- 交付工作 -->{{$t('job_detail.JFGZ')}}
								</div>
							</div>
						</div>
						<div class="center_four" v-for="item in jobInfo.employeeJob.submit_list" :key="item.job_submit_id">
							<div class=" flex-start">
								<div class="list_name" style="padding-top: 12px;">
									<!-- 文件上传 -->{{$t('job_detail.WJSC')}}
								</div>
								<div class="flex-item">
									<div class="flex-center filerow" v-for="file in item.submit_files" :key="file.url">
										<div class="flex-item filename">{{file.name}}</div>
										<el-button type="primary" class="btn" @click="$util.downFileUrl(file.url,file.name)"><!-- 点击下载 -->{{$t('job_detail.DJXZ')}}</el-button>
									</div>
								</div>
								
							</div>
							
							<div class="four_list">
								<div class="list_name">
									<!-- 交付说明 -->{{$t('job_detail.JFSM')}}
								</div>
								<div class="list_msg">
									{{item.submit_description}}
								</div>
								
							</div>
							<div class="four_list">
								<div class="list_name">
									<!-- 交付时间 -->{{$t('job_detail.JFSJ')}}
								</div>
								<div class="list_msg">
									{{$util.rTime(item.submit_time)}}
								</div>
							</div>
						</div>
						
					</template>
				</div>

			</div>
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import countdown from '../../components/uni-countdown.vue';
	import scroll from '@/utils/scroll.js';
	import {
		debounce
	} from '@/utils/debounce.js';
	export default {
		components: {
			Homeright,
			countdown
		},
		data() {
			return {
				job_id:'',
				input:'',
				deadline4: Date.now() + (new Date().setHours(23, 59, 59) - Date.now()),
				active: 0,
				listData:{
					pageSize: 15,
					keyword: '',
					skill: [],					
					salary_low: '',
					salary_high: '',
					entrust_method:[],
					job_method:'',
					fast_method:'',
					job_nature: '',
					sortRules: {
						hot_score: 'DESC',
						job_id: 'DESC',
						salary_low: 'DESC',
						create_time: 'DESC',
						job_end_time: 'DESC'
					}
				},
				

				skillList:[],
				entrust:[],
				jobNature:[],
				jobInfo:{},
				
				jobMethod:[],
				tripMethod:[],
				fastMethod:[],
				payMethod:[],
				operTimeMethod:[],
				colors:['#567BB6', '#567BB6', '#567BB6'],
				
				showallskill:false,
				tenskill:[],
				allskill:[],
			};
		},
		created() {
			// this.getList();
			// scroll.scrollToBottom(this.getList);
			this.job_id=this.$route.query.job_id;
			this.getDetail();
			this.getSkill();
			this.getUnit();
		},
		computed:{
		    watchlistData(){
		        return JSON.parse(JSON.stringify(this.listData));
		    }
		},
		watch:{
			watchlistData:{
				handler:function(nval,oval){					
					this.link(nval);
				},
				deep:true			
			},
			
		},
		methods: {
			gopin(){
				
				if(this.jobInfo.user_role==1){
					this.$router.push({name:'qypage',query:{user_id:this.jobInfo.user_id},params:{scrollId:'tabbar'}});
				}else{
					this.$router.push({name:'people_page',query:{user_id:this.jobInfo.user_id}});
				}
				
			},
			share:function(id,type,title){
				var href=this.$config.website+'job_detail?job_id='+id;				
				this.$util.share(href,type,title);
			},
			downall(){
				if(this.jobInfo.related_files.length>0){
					for (var i = 0; i < this.jobInfo.related_files.length; i++) {
						this.$util.downFileUrl(this.jobInfo.related_files[i].url,this.jobInfo.related_files[i].name);
					}
				}
				
			},
			touser(){
				if(this.jobInfo.user_role==0){
					this.$router.push({path:'/people_page',query:{user_id:this.jobInfo.user_id}});
				}else{
					this.$router.push({path:'/qypage',query:{user_id:this.jobInfo.user_id}});
				}
			},
			link:debounce(function(obj){
				this.$router.replace({name:'look_job',params:obj});
			}),
			//申请工作
			async applyJob(){
				this.$confirm(this.$t('QDBMM')+'?', this.$t('TISHI'), {				 
				  type: 'warning'
				}).then(async () => {
					let res=await this.$request.get('/api/custJobManage/applyJob',{job_id:this.job_id});
					this.getDetail();
				}).catch(() => {
				           
				});
				
				
			},
			//取消申请工作
			async quxiao(){
				this.$confirm(this.$t('QRQXM')+'?', this.$t('TISHI'), {				 
				  type: 'warning'
				}).then(async () => {
					let res=await this.$request.get('/api/custJobManage/cancelApplyJob',{job_id:this.job_id});
					this.getDetail();
				}).catch(() => {
				           
				});
				
				
			},
			//收藏
			async fav(){
				let res=await this.$request.get('/api/custJobManage/collectJob',{job_id:this.job_id});				
				this.jobInfo.is_collect=this.jobInfo.is_collect==1?0:1;				
			},
			//获取工作
			async getDetail() {				
				let res=await this.$request.get('/api/job/getJob',{job_id:this.job_id});
				this.jobInfo=res.data;
				
			},
			//获取业务技能数据
			async getSkill(){
				let res=await this.$request.get('/api/setjobs/getAllSkillList');
				
				this.skillList=res.data.records;
				let list=[],list2=[];
				for (var i = 0; i < this.skillList.length; i++) {
					for (var a = 0; a < this.skillList[i].children.length; a++) {
						if(list.length<10){
							list.push(this.skillList[i].children[a]);
						}
						list2.push(this.skillList[i].children[a]);
						
					}
				}
				this.tenskill=list;
				this.allskill=list2;
				console.log('技能',list,list2);
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.jobNature=res.data.job_nature.data;
				this.entrust=res.data.entrust_method.data;
				this.jobMethod=res.data.job_method.data;
				this.tripMethod=res.data.trip_method.data;
				this.payMethod=res.data.pay_method.data;
				this.operTimeMethod=res.data.oper_time_method.data;
				this.fastMethod=res.data.fast_method.data;
				
			},
			//交付文件
			async openJfls(job_manage_id,status,job_id){
				let res=await this.$request.get('/api/job/submitFileList',{job_manage_id:job_manage_id});
				
				
			},
			getTimestamp:function(date){
				return(Date.parse(new Date(date))/1000);
			},

		}
	};
</script>

<style lang="less" scoped>
	.steps{display: flex;align-items: flex-start;
		.stepitem{flex:1;display: flex;flex-direction: column;align-items: center;position: relative;
			&:before{content:"";position: absolute;top:14px;left: 0;right: 0;height: 2px;background-color: #eee;}
			&:first-child:before{left:50%;}
			&:last-child:before{right:50%}
			.no{width: 30px;height: 30px;display: flex;justify-content: center; align-items: center;background-color: #eee;border-radius: 50%;color: #666;font-size: 14px;position: relative;z-index: 2;}
			.name{font-size: 12px; margin-top: 10px;}
			&.active{
				&:before{background-color: #567BB6;}
				.no{background-color: #567BB6;color: #fff;}
				.name{color:#567BB6;}
			}
		}
	}
	.job_detail {
		min-height: 100%;
		background-color: #212121;

		.center_left {
			width: 286px;
			min-height: 733px;
			background: #567BB6;
			border-radius: 3px;
			padding: 30px;
			box-sizing: border-box;

			.left_title {
				height: 30px;
				font-size: 15px;
				border-bottom: 1px solid #fff;
				color: #fff;
			}

			.left_input_box {
				display: flex;
				align-items: center;
				color: #fff;
				margin-top: 15px;
				font-size: 15px;

				.input {
					width: 150px;
					height: 34px;
					background: #FFFFFF;
					border-radius: 7px;
					border: 1px solid #567BB6;
					margin-right: 8px;
				}
			}

			.el-checkbox-group {
				margin-bottom: 30px;
			}

			
			.el-radio-group{display: block;margin-bottom: 30px;}
			.el-radio{display: block; margin: 15px 0; color: #fff;}
			.el-checkbox {
				width: 100%;
				margin: 10px 0;
				color: #fff;
			}
		}

		.home_center {
			display: flex;
			justify-content: flex-end;
		}

		.center_people {
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			// align-items: center;
			padding-top: 0;
			color: #FFFFFF;

			.center_top {
				width: 878px;
				background-color: #000000;
				padding: 25px;
				border-radius: 7px;
				margin-bottom: 10px;

				.top_title {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 19px;
					margin-bottom: 10px;

					.more {
						flex-shrink: 0;
						margin-left: 30px;
						width: 27px;
						height: 27px;
					}
				}

				.top_id {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.id_left {
						display: flex;
						align-items: center;

						.id {
							font-size: 12px;
							color: #fff;
						}

						.QY {
							font-size: 12px;
							padding: 5px 15px;
							border-radius: 7px;
							border: 1px solid #B65664;
							margin: 0 10px;
						}
					}

					.pin {
						display: flex;
						align-items: center;
						font-size: 12px;
						position: relative;
						cursor: pointer;
						.el-rate {
							margin-left: 10px;
						}
						.morepin{position: absolute;top:100%;right:0;background-color: #222;border-radius: 5px;display: none;
							.mitem{padding: 10px;display: flex;align-items: center;white-space: nowrap;}
						}
						&:hover .morepin{display: block;}
					}
				}
			}

			.center_msg {
				width: 878px;
				background-color: #000000;
				padding: 25px;
				border-radius: 7px;

				.msg_title {
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid #567BB6;

					.title {
						font-size: 17px;
						font-weight: bold;
					}

					.time {
						color: #567BB6;
						font-size: 12px;
					}
				}

				.activity_box {
					width: 100%;
					padding: 10px;
					background-color: #000000;
					border-radius: 5px;
					margin: 10px 0;
					display: flex;
					justify-content: space-between;
					margin-bottom: 20px;
					box-sizing: border-box;

					.box_img {
						width: 116px;
						height: 116px;
						border-radius: 3px;
					}

					.box_msg {
						width: 450px;
						font-size: 15px;
						color: #fff;

						.msg_name {
							line-height: 1.3;
							margin-bottom: 10px;
							display: flex;
							align-items: center;

							.name_box {
								display: flex;
								flex-wrap: wrap;
								font-size: 10px;

								.box {
									padding: 3px 10px;
									background-color: #567BB6;
									border-radius: 7px;
									margin: 5px;
									margin-top: 0;
								}
							}
						}

						.msg_msg {
							width: 400px;
							font-size: 12px;
							text-overflow: -o-ellipsis-lastline;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							line-clamp: 2;
							-webkit-box-orient: vertical;
							line-height: 1.3;
							margin-bottom: 10px;
						}

						.msg_text {
							font-size: 12px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}

					.box_center {
						width: 240px;
						border-left: 1px solid #606060;
						// border-right: 1px solid #606060;
						padding: 0 10px;
						color: #fff;
						font-size: 10px;

						.center_text {
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 5px;

							.text_text {
								width: 50px;
								flex-shrink: 0;
							}

							.text_box {
								border-radius: 3px;
								width: 170px;
								line-height: 26px;
								border: 1px solid #344A6E;
								padding-left: 5px;

							}
						}
					}

					.box_btn {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 40px;
						color: #fff;
						font-size: 10px;

						.btn {
							display: flex;
							flex-direction: column;
							align-items: center;
							margin: 10px 0;

							.fenx {
								width: 20px;
								height: 20px;
								margin-bottom: 10px;
							}
						}
					}
				}

				.center_msg_title {
					width: 843px;
					font-size: 17px;
					font-weight: bold;
					margin-top: 20px;
					margin-bottom: 10px;
					padding-left: 10px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.title_right{
						display: flex;
						align-items: center;
						font-size: 12px;
						.btn1{
							
							min-width: 77px;
							height: 21px;
							background: #567BB6;
							border-radius: 3px;
							border: 1px solid #567BB6;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 10px;
						}
					}
				}

				.one_box {
					display: flex;
					justify-content: space-between;

					.one_box_left {
						width: 497px;
						display: flex;
						align-items: center;
						background-color: #16171C;
						padding: 25px 0;

						.left_menu {
							width: 160px;
							height: 85px;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							font-size: 14px;

							.menu_num {
								margin-bottom: 10px;
								display: flex;
								align-items: center;

								span {
									color: #567BB6;
									font-size: 40px;
									font-weight: bold;
								}
							}
						}

						.xian {
							width: 1px;
							height: 85px;
							background-color: #979797;
						}

					}

					.one_box_right {
						width: 335px;
						height: 137px;
						background: #16171C;
						border-radius: 9px;
						display: flex;
						align-items: center;
						flex-direction: column;
						justify-content: center;
						.right_name{
							font-size: 19px;
							color: #567BB6;
							font-weight: bold;
							margin-bottom: 10px;
						}
						.right_msg{
							display: flex;
							align-items: center;
							color: #fff;
							margin-bottom: 10px;
							.number{
								color: #fff!important;
							}
							.msg_text{
								flex-shrink: 0;
							}
						}
						.right_time{
							color: #567BB6;
							margin-bottom: 10px;
						}
						.btn_box{
							display: flex;
							align-items: center;
							.btn1{
								padding: 0;
								width: 34px;
								height: 34px;
								border-radius: 7px;
								border: 1px solid #567BB6;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #16171C;
								.img1{
									width: 20px;
									height: 20px;
								}
								position: relative;
								&:hover .sharemenu{display: block;}
							}
							.btn2{
								width: 137px;
								height: 34px;
								border-radius: 7px;
								border: 1px solid #567BB6;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #567BB6;
								font-size: 12px;
								color: #fff;
							}
						}
					}

				}
				.center_two{
					width: 843px;
					min-height: 86px;
					background: #16171C;
					border-radius: 9px;
					padding: 15px;
					box-sizing: border-box;
					display: flex;
					flex-wrap: wrap;
					.two_menu{
						
						font-size: 12px;
						line-height: 20px;
						margin-right: 20px;
						font-size: 12px;
					}
				}
				.center_three{
					width: 843px;
					min-height: 86px;
					background: #16171C;
					border-radius: 9px;
					padding: 15px;
					box-sizing: border-box;
					
					font-size: 12px;
					line-height: 20px;
				}
				.center_four{
					
					display: flex;flex-direction: column;align-items: center;
					margin-top: 10px;
					background-color: #16171C;
					padding: 15px ;
					.four_list{
						width: 620px;
						display: flex;
						align-items: center;
						margin-top: 10px;
						
					}
					.list_name{
						width: 70px;
						margin-right: 10px;
						font-size: 14px;
						flex-shrink: 0;
					}
					.list_msg{
						line-height: 1.3;
						font-size: 12px;
						width: 430px;
						padding: 10px 0;
						border-bottom: 1px solid #979797;
					}
					.el-input__inner{
						border: 1px solid #567BB6;
						background-color: #16171C;
						color: #fff;
						width: 430px;
					}
					.btn{
						margin-left: 10px;
						background-color: #567BB6;
						border: 1px solid #567BB6;
						color: #fff;
					}
				}
			}


		}
	}
	.filerow{margin-top: 10px;
		&:first-child{margin-top: 0;}
		.el-button{
			background-color: #567BB6;
			border: 1px solid #567BB6;
			color: #fff;
		}
	}
	.filename{width: 430px; display: flex;flex-wrap: wrap;word-break: break-all; box-sizing: border-box; min-height: 40px;padding: 10px;border-radius: 4px;line-height: 1.3;border:1px solid #567BB6;font-size: 12px;}
	.list_box{
		margin:10px 0;
		background-color: #16171C;
		border-radius: 5px;
		padding: 15px;
		box-sizing: border-box;
		
		color: #fff;
		.box_user{
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			.user{
				width: 43px;
				height: 43px;
				border-radius: 5px;
				margin-right: 20px;
			}
			.user_name{
				width: 580px;
				font-size: 14px;
				display: flex;
				align-items: center;
			}
			.user_right{
				font-size: 12px;
				color: #567BB6;
			}
			.el-button{
				background-color: #567BB6;
				border: 1px solid #567BB6;
				color: #fff;
			}
		}
		.box_form{border-top: 1px solid #000;margin-top: 10px;padding-top: 10px;
			.filerow{margin-top: 10px;
				&:first-child{margin-top: 0;}
				.el-button{
					background-color: #567BB6;
					border: 1px solid #567BB6;
					color: #fff;
				}
			}
			.filename{display: flex;flex-wrap: wrap;word-break: break-all; box-sizing: border-box; min-height: 40px;padding: 10px;border-radius: 4px;line-height: 1.3;border:1px solid #567BB6;font-size: 12px;}
			.list_name{
				width: 120px;
				margin-right: 15px;
				text-align: right;
				font-size: 14px;
			}
			.form_list{
				display: flex;
				align-items: center;
				margin-top: 10px;
				
				.el-input{
					width: 429px;
					margin-right: 10px;
				}
				.el-input__inner{
					
					color: #fff;
					font-size: 12px;
					background-color: #000;
					border: 1px solid #567BB6;
				}
				.list_center{
					width: 429px;
					margin-right: 10px;
					border-bottom: 1px solid #979797;
					color: #fff;
					line-height: 35px;
					font-size: 12px;
				}
				.el-button{
					background-color: #567BB6;
					border: 1px solid #567BB6;
					color: #fff;
				}
			}
			.form_time{
				display: flex;
				align-items: center;
				
				
				.time{
					color: #567BB6;
					font-size: 12px;
				}
			}
			.zdpf{display: inline-flex;align-items: center;height: 20px;background-color: #567BB6;border-radius: 20px;font-size: 12px;color: #fff;padding:0 10px; margin-left: 10px;}
			.form_msg{
				margin-top: 10px;
				color: #fff;
				font-size: 12px;
				line-height: 1.3;
			}
		}
	}
</style>
