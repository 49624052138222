<template>
	
	
		<div v-if="simple" :style="{color: color}"><template v-if="showDay&&d>0">{{ d }}{{$t('TIAN')}}</template>{{ h }}{{ showColon ? ':' : $t('HH') }}{{ i }}{{ showColon ? ':' : $t('MM') }}{{ s }}{{ showColon ? '' : $t('SS') }}</div>
		<div class="uni-countdown" :class="{large:size=='lg'}" v-else>
			<span v-if="showDay&&d>0" :style="{ borderColor: borderColor, color: color, backgroundColor: backgroundColor }" class="uni-countdown__number">{{ d }}</span>
			<span v-if="showDay&&d>0" :style="{ color: splitorColor }" class="uni-countdown__splitor"><!-- 天 -->{{$t('TIAN')}}</span>
			<span :style="{ borderColor: borderColor, color: color, backgroundColor: backgroundColor }" class="uni-countdown__number">{{ h }}</span>
			<span :style="{ color: splitorColor }" class="uni-countdown__splitor">{{ showColon ? ':' : $t('HH') }}</span>
			<span :style="{ borderColor: borderColor, color: color, backgroundColor: backgroundColor }" class="uni-countdown__number">{{ i }}</span>
			<span :style="{ color: splitorColor }" class="uni-countdown__splitor">{{ showColon ? ':' : $t('MM') }}</span>
			<span :style="{ borderColor: borderColor, color: color, backgroundColor: backgroundColor }" class="uni-countdown__number">{{ s }}</span>
			<span v-if="!showColon" :style="{ color: splitorColor }" class="uni-countdown__splitor">{{$t('SS')}}</span>
		</div>
	
</template>
<script>
	/**
	 * Countdown 倒计时
	 * @description 倒计时组件
	 * @tutorial https://ext.dcloud.net.cn/plugin?id=25
	 * @property {String} backgroundColor 背景色
	 * @property {String} color 文字颜色
	 * @property {Number} day 天数
	 * @property {Number} hour 小时
	 * @property {Number} minute 分钟
	 * @property {Number} second 秒
	 * @property {Number} timestamp 时间戳
	 * @property {Boolean} showDay = [true|false] 是否显示天数
	 * @property {Boolean} showColon = [true|false] 是否以冒号为分隔符
	 * @property {String} splitorColor 分割符号颜色
	 * @event {Function} timeup 倒计时时间到触发事件
	 * @example <uni-countdown :day="1" :hour="1" :minute="12" :second="40"></uni-countdown>
	 */
	export default {
		name: 'countdown',
		props: {
			showDay: {
				type: Boolean,
				default: true
			},
			showColon: {
				type: Boolean,
				default: true
			},
			backgroundColor: {
				type: String,
				default: '#FFFFFF'
			},
			borderColor: {
				type: String,
				default: '#000000'
			},
			color: {
				type: String,
				default: '#000000'
			},
			splitorColor: {
				type: String,
				default: '#000000'
			},
			day: {
				type: Number,
				default: 0
			},
			hour: {
				type: Number,
				default: 0
			},
			minute: {
				type: Number,
				default: 0
			},
			second: {
				type: Number,
				default: 0
			},
			timestamp: {
				type: Number,
				default: 0
			},
			size: {
				type: String,
				default:'sm'
			},
			simple: {
				type: Boolean,
				default:false
			},
			isnvue: {
				type: Boolean,
				default:false
			},
		},
		data() {
			return {
				timer: null,
				syncFlag: false,
				d: '00',
				h: '00',
				i: '00',
				s: '00',
				leftTime: 0,
				seconds: 0
			};
		},
		watch: {
			day(val) {
				this.changeFlag();
			},
			hour(val) {
				this.changeFlag();
			},
			minute(val) {
				this.changeFlag();
			},
			second(val) {
				this.changeFlag();
			}
		},
		created: function(e) {
			this.startData();
			//console.log(this.timestamp);
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
		methods: {
			toSeconds(timestamp, day, hours, minutes, seconds) {
				if (timestamp) {
					return timestamp - parseInt(new Date().getTime() / 1000, 10);
				}
				return day * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60 + seconds;
			},
			timeUp() {
				clearInterval(this.timer);
				this.$emit('timeup');
			},
			countDown() {
				let seconds = this.seconds;
				let [day, hour, minute, second] = [0, 0, 0, 0];
				if (seconds > 0) {
					day = Math.floor(seconds / (60 * 60 * 24));
					hour = Math.floor(seconds / (60 * 60)) - (day * 24);
					minute = Math.floor(seconds / 60) - (day * 24 * 60) - (hour * 60);
					second = Math.floor(seconds) - (day * 24 * 60 * 60) - (hour * 60 * 60) - (minute * 60);
				} else {
					this.timeUp();
				}
				if (day < 10) {
					day = '0' + day;
				}
				if (hour < 10) {
					hour = '0' + hour;
				}
				if (minute < 10) {
					minute = '0' + minute;
				}
				if (second < 10) {
					second = '0' + second;
				}
				this.d = day;
				this.h = hour;
				this.i = minute;
				this.s = second;
			},
			startData() {
				this.seconds = this.toSeconds(this.timestamp, this.day, this.hour, this.minute, this.second);
				if (this.seconds <= 0) {
					return;
				}
				this.countDown();
				this.timer = setInterval(() => {
					this.seconds--;
					if (this.seconds < 0) {
						this.timeUp();
						return;
					}
					this.countDown();
				}, 1000);
			},
			changeFlag() {
				if (!this.syncFlag) {
					this.seconds = this.toSeconds(this.timestamp, this.day, this.hour, this.minute, this.second);
					this.startData();
					this.syncFlag = true;
				}
			}
		}
	};
</script>
<style scoped lang="less" scoped>
	.uni-countdown {
		
		display: flex;
		align-items: center;
		padding: 2rpx 0;
		
	}

	.uni-countdown__splitor {
		
		justify-content: center;
		line-height: 1.2;
		padding:0 5rpx;
		font-size: 12px;
	}

	.uni-countdown__number {
		
		justify-content: center;
		align-items: center;
		width: 50rpx;
		height: 40rpx;
		
		margin: 5rpx;
		text-align: center;
		font-size: 12px;
		border-radius:3px;
	}
	.uni-countdown.large{
		.uni-countdown__splitor{ line-height:50px; font-size: 28px; padding:0 3px;}
		.uni-countdown__number{height: 50px; font-size: 28px; width:56px; border-radius:6px; background: linear-gradient(to bottom,#eee,#eee 50%,#f5f5f5 50%); font-weight: bold;}
	}
</style>